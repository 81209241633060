import { Button, DownloadIcon, Flex, FlexItem, Form, FormCheckbox, FormInput, FormRadioGroup, Header } from "@fluentui/react-northstar";
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { useState } from "react";
import AtenasSignature from "../components/AtenasSignature";
import EloSignature from "../components/EloSignature";
import OpusSignature from "../components/OpusSignature";
import SevenSignature from "../components/SevenSignature";
import TelosConsultoriaSignature from "../components/TelosConsuloriaSignature";

async function handleCaptureClick(name) {
  try {
    const companyValue = "email"
    const signatureBox = document.querySelector("#signature")
    const canvas = await html2canvas(signatureBox);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, `assinatura-${name}.png`, 'image/png')
  } catch (error) {
    console.log(error)
  }
}

function mailToEmail(email) {
  const stringMailTo = 'mailto:' + email
  return stringMailTo
}

const signatureSwitch = (company, name, email, role, telephone, extraPhone, checkExtraPhone) => {
  switch (company) {
    case 'opus':
      return OpusSignature(name, email, role, telephone, extraPhone, checkExtraPhone)
    case 'telosConsultoria':
      return TelosConsultoriaSignature(name, email, role, telephone, extraPhone, checkExtraPhone)
    case 'elo':
      return EloSignature(name, email, role, telephone, extraPhone, checkExtraPhone)
    case 'grupoSeven':
      return SevenSignature(name, email, role, telephone, extraPhone, checkExtraPhone)
    case 'atenas':
      return AtenasSignature(name, email, role, telephone, extraPhone, checkExtraPhone)
    default:
      return OpusSignature(name, email, role, telephone, extraPhone, checkExtraPhone)
  }
}

const ExtraPhone = ([extraPhone, setExtraPhone], checkExtraPhone) => {
  return (
    <FormInput
      fluid
      inverted
      type="number"
      onChange={event => {
        let telephone = event.target.value
        if (telephone != 0) {
          if (telephone.length >= 11) {
            let ddd = telephone.slice(0, 2)
            let firstDigits = telephone.slice(2, 7)
            let lastDigits = telephone.slice(7)
            let maskedTelephone = `+55 (${ddd}) ${firstDigits}-${lastDigits}`
            setExtraPhone(maskedTelephone)
          } else {
            let ddd = telephone.slice(0, 2)
            let firstDigits = telephone.slice(2, 6)
            let lastDigits = telephone.slice(6)
            let maskedTelephone = `+55 (${ddd}) ${firstDigits}-${lastDigits}`
            setExtraPhone(maskedTelephone)
          }
        }
      }}
    />
  )
}

const CheckboxChecked = ([checkExtraPhone, setCheckExtraPhone], [extraPhone, setExtraPhone]) => {
  return (
    <Flex>
      <Flex>
        <FormCheckbox label="Telefone Extra" onChange={() => {
          setCheckExtraPhone(!checkExtraPhone)
          setExtraPhone('')
        }} /></Flex>
      {checkExtraPhone ? ExtraPhone([extraPhone, setExtraPhone], checkExtraPhone) : ''}
    </Flex>
  )
}

const Signature = () => {
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [email, setEmail] = useState('')
  const [telephone, setTelephone] = useState('')
  const [checkExtraPhone, setCheckExtraPhone] = useState(false)
  const [extraPhone, setExtraPhone] = useState('')
  const [company, setCompany] = useState({
    name: 'company',
    key: 'Opus',
    label: 'Grupo Opus',
    value: 'opus',
  })
  const items = [
    {
      name: 'company',
      key: 'Opus',
      label: 'Opus',
      value: 'opus',
    },
    {
      name: 'company',
      key: 'TelosConsultoria',
      label: 'Telos',
      value: 'telosConsultoria',
    },
    {
      name: 'company',
      key: 'Elo',
      label: 'Elo',
      value: 'elo',
    },
    {
      name: 'company',
      key: 'atenas',
      label: 'Atenas',
      value: 'atenas',
    },
    {
      name: 'company',
      key: 'GrupoSeven',
      label: 'Seven',
      value: 'grupoSeven',
    },
  ]

  return (<Flex column={true} style={{ minHeight: '100vh' }}>
    <Header
      align="center"
      content="Gerador de Assinatura"
      data-builder-id="sthoz347aq"
      description="Assinatura para email"
    />
    <Form data-builder-id="9on9cfv1z9" style={{ marginTop: "0rem" }}>
      <Flex
        data-builder-id="2xmnm8lsney"
        gap="gap.large"
        space="around"
      >
        <FormRadioGroup
          label="Empresa"
          fluid
          horizontal
          defaultCheckedValue="opus"
          items={items}
          onCheckedValueChange={(e, data) => {
            setCompany(data.value)
            console.log(company)
            console.log(data.value)
          }}
        />
      </Flex>
      <Flex
        data-builder-id="2xmnm8lsney"
        gap="gap.large"
        hAlign='center'
        vAlign='center'
      ><FlexItem size='350px'>
          <FormInput
            data-builder-id="ukobid3q5d7"
            label="Nome"
            inverted
            fluid
            maxLength={24}
            onChange={event => setName(event.target.value.toUpperCase())}
          />
        </FlexItem>
        <FlexItem size='350px'>
          <FormInput
            data-builder-id="ukbn6ojgt1q"
            label="Cargo"
            maxLength={39}
            inverted
            fluid
            onChange={event => setRole(event.target.value)}
          />
        </FlexItem>
      </Flex>
      <Flex
        data-builder-id="2xmnm8lsney"
        gap="gap.large"
        hAlign='center'
        vAlign='center'
      ><FlexItem size='350px'>
          <FormInput
            data-builder-id="ukobid3q5d7"
            label="Email"
            maxLength={39}
            fluid
            inverted
            onChange={event => setEmail(event.target.value)}
          />
        </FlexItem>
        <FlexItem size='350px'>
          <FormInput
            data-builder-id="ukbn6ojgt1q"
            label="Telefone"
            maxlength={1}
            fluid
            inverted
            type="number"
            onChange={event => {
              let telephone = event.target.value
              if (telephone != 0) {
                if (telephone.length >= 11) {
                  let ddd = telephone.slice(0, 2)
                  let firstDigits = telephone.slice(2, 7)
                  let lastDigits = telephone.slice(7)
                  let maskedTelephone = `+55 (${ddd}) ${firstDigits}-${lastDigits}`
                  setTelephone(maskedTelephone)
                } else {
                  let ddd = telephone.slice(0, 2)
                  let firstDigits = telephone.slice(2, 6)
                  let lastDigits = telephone.slice(6)
                  let maskedTelephone = `+55 (${ddd}) ${firstDigits}-${lastDigits}`
                  setTelephone(maskedTelephone)
                }
              }
            }}
          />
        </FlexItem>
      </Flex>
      <Flex hAlign="center" style={{ marginLeft: '13px' }}>
        {CheckboxChecked([checkExtraPhone, setCheckExtraPhone], [extraPhone, setExtraPhone])}
      </Flex>
    </Form>
    <Flex
      data-builder-id="3mcr8oerqyu"
      gap="gap.large"
      space="evenly"
      styles={{
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >{signatureSwitch(company, name, email, role, telephone, extraPhone, checkExtraPhone)}
    </Flex>
    <Form>
      <Flex hAlign="center" gap="gap.small">
        <Button primary onClick={event => handleCaptureClick(name)} >
          Download<DownloadIcon />
        </Button>
        <a href={mailToEmail(email)}>
          <Button primary type="button">
            Email
          </Button>
        </a>
      </Flex>
    </Form>
  </Flex >
  )
}
export default Signature;