import { Box, Flex, Image, Text } from "@fluentui/react-northstar";
import logo_atenas from '../images/logo_atenas.png';
import '../index.css';

const DivPhone = (telephone, checkExtraPhone, extraPhone) => {
  if (!checkExtraPhone) {
    return (
      <Text
        id='telephone'
        content={telephone}
        fluid
        align="left"
        styles={{
          marginTop: '20px',
          color: '#494848',
          fontSize: '11px',
        }} />
    )
  } else {
    return (
      <div>
        <Text
          id='telephone'
          content={telephone}
          fluid
          align="left"
          styles={{
            marginTop: '10px',
            color: '#494848',
            fontSize: '11px',
          }} />
        <Text
          id='telephone'
          content={extraPhone}
          fluid
          align="left"
          styles={{
            color: '#494848',
            fontSize: '11px',
          }} />
      </div>
    )
  }
}


const AtenasSignature = (name, email, role, telephone, extraPhone, checkExtraPhone) => {

  return (
    <Box id='signature'
    ><Flex
      gap="0"
      padding='0'
      styles={{
        minHeight: '124px',
        minWidth: '530px',
        maxHeight: '124px',
        maxWidth: '367px',
        backgroundColor: 'white'
      }}>
        <Flex.Item >
          <Box
            style={{
              minWidth: '340px',
              marginTop: '7px',
              marginBottom: '7px',
              marginRight: '5px',
              marginLeft: '5px',
              fontFamily: 'Verdana, sans-serif',
              fontStyle: 'normal',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
            }} >
            <Text
              id='name'
              content={name}
              fluid
              align="left"
              styles={{
                color: '#494848',
                fontSize: '16px'
              }} />
            <Text
              id='role'
              content={role}
              fluid
              align="left"
              styles={{
                color: '#494848',
                fontSize: '11px',
                marginTop: '-5px'
              }} />
            <Text
              id='email'
              content={email}
              fluid
              align="left"
              styles={{
                marginTop: '20px',
                color: '#494848',
                fontSize: '11px',
                letterSpacing: '0.2px'
              }} />
            {DivPhone(telephone, checkExtraPhone, extraPhone)}
          </Box>
        </Flex.Item>
        <Flex.Item size="145">
          <Box style={{
            background: 'linear-gradient(#ffd041, #ab8100)',
            marginTop: '11px',
            marginBottom: '11px',

          }}>
            <Box
              fluid
              style={{
                background: 'white',
                position: 'relative',
                margin: 'auto',
                marginLeft: '3px',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Image src={logo_atenas}
                style={{
                  position: 'absolute',
                  margin: 'auto',
                  height: '95%',
                  width: 'auto',
                  marginLeft: '3px',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }} />
            </Box>
          </Box>
        </Flex.Item>
      </Flex>
    </Box >
  )
}
export default AtenasSignature;