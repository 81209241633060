import { Box, Flex, Image, Text } from "@fluentui/react-northstar";
import logo_seven from '../images/logo_seven.png';
import '../index.css';

const DivPhone = (telephone, checkExtraPhone, extraPhone) => {
  if (!checkExtraPhone) {
    return (
      <Text
        id='telephone'
        content={telephone}
        fluid
        align="left"
        styles={{
          marginTop: '30px',
          color: '#3a5379',
          fontSize: '12px',
        }} />
    )
  } else {
    return (
      <div>
        <Text
          id='telephone'
          content={telephone}
          fluid
          align="left"
          styles={{
            marginTop: '14px',
            color: '#3a5379',
            fontSize: '12px',
          }} />
        <Text
          id='telephone'
          content={extraPhone}
          fluid
          align="left"
          styles={{
            color: '#3a5379',
            fontSize: '12px',
          }} />
      </div>
    )
  }
}

const SevenSignature = (name, email, role, telephone, extraPhone, checkExtraPhone) => {

  return (
    <Box id='signature'
    ><Flex
      gap="0"
      padding='0'
      styles={{
        minHeight: '140px',
        minWidth: '540px',
        maxHeight: '140px',
        maxWidth: '6000px',
        backgroundColor: 'white'
      }}>
        <Flex.Item >
          <Box
            style={{
              width: '340px',
              marginTop: '8px',
              marginBottom: '8px',
              marginRight: '9px',
              marginLeft: '9px',
              fontFamily: 'Verdana, sans-serif',
              fontStyle: 'normal',
              fontWeight: 'bold',
              whiteSpace: 'nowrap'
            }} >
            <Text
              id='name'
              content={name}
              fluid
              align="left"
              styles={{
                color: '#3a5379',
                fontSize: '19px'
              }} />
            <Text
              id='role'
              content={role}
              fluid
              align="left"
              styles={{
                color: '#bea562',
                fontSize: '12px',
                marginTop: '-5px'
              }} />
            <Text
              id='email'
              content={email}
              fluid
              align="left"
              styles={{
                marginTop: '24px',
                color: '#3a5379',
                fontSize: '11px',
                letterSpacing: '0.2px'
              }} />
            {DivPhone(telephone, checkExtraPhone, extraPhone)}
          </Box>
        </Flex.Item>
        <Flex.Item size="220px">
          <Box style={{
            background: 'linear-gradient(#ffd041, #ab8100)',
          }}>
            <Box
              fluid
              style={{
                background: 'white',
                position: 'relative',
                minWidth: '200px',
                margin: 'auto',
                marginLeft: '4px',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Image src={logo_seven}
                style={{
                  position: 'absolute',
                  margin: 'auto',
                  height: '90%',
                  width: 'auto',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }} />
            </Box>
          </Box>
        </Flex.Item>
      </Flex>
    </Box >
  )
}
export default SevenSignature;