import { Box, Flex, Image, Text } from "@fluentui/react-northstar";
import logo_opus from '../images/logo_opus.png';
import '../index.css';

const DivPhone = (telephone, checkExtraPhone, extraPhone) => {
  if (!checkExtraPhone) {
    return (
      <Text
        id='telephone'
        content={telephone}
        fluid
        align="left"
        styles={{
          marginTop: '24px',
          color: '#3a5379',
          fontSize: '12px',
        }} />
    )
  } else {
    return (
      <div>
        <Text
          id='telephone'
          content={telephone}
          fluid
          align="right"
          styles={{
            marginTop: '12px',
            color: '#3a5379',
            fontSize: '12px',
          }} />
        <Text
          id='telephone'
          content={extraPhone}
          fluid
          align="right"
          styles={{
            color: '#3a5379',
            fontSize: '12px',
          }} />
      </div>
    )
  }
}

const OpusSignature = (name, email, role, telephone, extraPhone, checkExtraPhone) => {

  return (
    <Box id='signature'
    ><Flex
      gap="0"
      padding='0'
      styles={{
        minHeight: '140px',
        minWidth: '530px',
        maxHeight: '140px',
        maxWidth: '400px',
        backgroundColor: 'white'
      }}>
        <Flex.Item styles={{
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        }}>
          <Box
            style={{
              minWidth: '330px',
              marginTop: '8px',
              marginBottom: '8px',
              marginRight: '9px',
              marginLeft: '9px',
              fontFamily: 'Verdana, sans-serif',
              fontStyle: 'normal',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              alignContent: 'flex-start'
            }} >
            <Text
              id='name'
              content={name}
              fluid
              align="left"
              styles={{
                color: '#3a5379',
                fontSize: '19px'
              }} />
            <Text
              id='role'
              content={role}
              fluid
              align="left"
              styles={{
                color: '#98abbf',
                fontSize: '12px',
                marginTop: '-5px'
              }} />
            <Text
              id='email'
              content={email}
              fluid
              align="left"
              styles={{
                marginTop: '24px',
                color: '#3a5379',
                fontSize: '11px',
                letterSpacing: '0.2px'
              }} />
            {DivPhone(telephone, checkExtraPhone, extraPhone)}
          </Box>
        </Flex.Item>
        <Flex.Item size="160px">
          <Box style={{
            minWidth: '160px',
            borderWidth: '0px',
            borderLeftWidth: '1.5px',
            borderStyle: 'solid',
            borderColor: '#3a5379',
            marginTop: '5px',
            marginBottom: '5px',
          }}>
            <Box
              fluid
              style={{
                position: 'relative',
                minWidth: '160px',
                margin: 'auto',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Image src={logo_opus}
                style={{
                  position: 'absolute',
                  margin: 'auto',
                  height: 'auto',
                  width: '90%',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }} />
            </Box>
          </Box>
        </Flex.Item>
      </Flex>
    </Box >
  )
}
export default OpusSignature;